
import { Component, Vue, Prop } from 'vue-property-decorator';
import MBPAppForm from '@/components/mbp-app/MBPAppForm.vue';
import Anchor from '@/components/shared/Anchor.vue';
import PageHeader from '@/components/shared/PageHeader.vue';
import PageHeaderAction from '@/components/shared/PageHeaderAction.vue';
import PageHeaderNavigationAction from '@/components/shared/PageHeaderNavigationAction.vue';
import { MBPApp } from '@govflanders/mbp-admin-panel-shared';
import { cloneDeep } from 'lodash';
import ValidationErrorNotification from '@/components/shared/ValidationErrorNotification.vue';
import { mixins } from 'vue-class-component';
import PendingChangesGuard from '@/utility/forms/PendingChangesGuard.vue';
import RoleGuard from '@/utility/auth/RoleGuard.vue';

@Component({
  components: {
    Anchor,
    PageHeader,
    PageHeaderAction,
    PageHeaderNavigationAction,
    MBPAppForm,
    ValidationErrorNotification,
  },
})
export default class MBPAppEdit extends mixins(PendingChangesGuard, RoleGuard) {
  @Prop({
    type: String,
  })
  public id!: string;

  public value: MBPApp = null;
  public initialValue: MBPApp = null;
  public valueLoading: boolean;
  public error = '';

  private created() {
    this.valueLoading = true;
    this.$store
      .dispatch('MBPApp/get', this.id)
      .then(app => {
        this.value = cloneDeep(app);
        this.initialValue = cloneDeep(app);

        this.value = { acm: { clientId: '', clientSecret: '' }, links: [], localJobs:{}, ...this.value };
        this.valueLoading = false;
      })
      .catch(e => (this.error = e));
  }

  public revert() {
    this.value = cloneDeep(this.initialValue);
  }

  public save() {
    this.$store
      .dispatch('MBPApp/update', this.value)
      .then(updatedValue => {
        this.value = updatedValue;
        this.initialValue = cloneDeep(updatedValue);
        this.$toast.success(this.$t('mbp-app.edit.save-success'));
      })
      .catch(e => {
        console.error(e);
        this.$toast.error(this.$t('mbp-app.edit.save-error'));
      });
  }
}
